export const scenarios = [
  {
    name: "Hughes Fire",
    start_loc: [34.579078, -118.555699],
    wind_speed: 30,
    wind_dir: 303,
    fmc: 0.12,
  },
  {
    name: "Tubbs Fire",
    start_loc: [38.610427, -122.628039],
    wind_speed: 60,
    wind_dir: 320,
    fmc: 0.17,
  },
  {
    name: "Marshall Fire",
    start_loc: [39.956173, -105.230447],
    wind_speed: 60,
    wind_dir: 190,
    fmc: 0.12,
  },
  {
    name: "Dixie Fire",
    start_loc: [39.875946, -121.377224],
    wind_speed: 35,
    wind_dir: 105,
    fmc: 0.08,
  },
  {
    name: "Park Fire",
    start_loc: [39.771753, -121.765409],
    wind_speed: 30,
    wind_dir: 90,
    fmc: 0.12,
  },
  {
    name: "Mountain Fire",
    start_loc: [34.319049, -118.967801],
    wind_speed: 50,
    wind_dir: 330,
    fmc: 0.16,
  },
  {
    name: "Camp Fire",
    start_loc: [39.813543, -121.434802],
    wind_speed: 40,
    wind_dir: 345,
    fmc: 0.05,
  },
  {
    name: "Palisades Fire",
    start_loc: [34.075837, -118.544602],
    wind_speed: 40,
    wind_dir: 275,
    fmc: 0.16,
  },
  {
    name: "Eaton Fire",
    start_loc: [34.185422, -118.089811],
    wind_speed: 35,
    wind_dir: 306,
    fmc: 0.16,
  },
  {
    name: "Lahaina Fire",
    start_loc: [20.88477, -156.66037],
    wind_speed: 65,
    wind_dir: 315,
    fmc: 0.14,
  },
];
