import { ChevronRight, History } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import PropagationHistoryStack from "./PropagationHistoryStack";
import { useRef } from "react";

export default function PreviousRuns(props) {
  const { container, toggleHistoricalFires } = props;
  const theme = useTheme();

  return (
    // <Slide in direction='right'>
    <>
      <Stack direction="row" p={3} pb={0} alignItems="center" spacing={1}>
        <History htmlColor={theme.palette.text.truePrimary} />
        <Typography variant="h5" color={theme.palette.text.truePrimary}>
          Previous Runs
        </Typography>
        <Tooltip title="Historical Fires">
          <IconButton
            onClick={toggleHistoricalFires}
            sx={{
              ml: "auto !important",
            }}
          >
            <ChevronRight
              htmlColor={theme.palette.text.onTrueSecondary}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                borderRadius: 100,
              }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
      <Box flex={1} overflow="auto" p={3} pt={1}>
        <PropagationHistoryStack
        // setPropagationConfigOpen={setPropagationConfigOpen}
        />
      </Box>
    </>
    // </Slide>
  );
}
