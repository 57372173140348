import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import PropagationParametersProvider from "./components/contexts/PropagationParametersProvider.jsx";
import PropagationLayersProvider from "./components/contexts/PropagationLayerStateProvider.jsx";
import PropagationHistoryProvider from "./components/contexts/PropagationHistoryProvider.jsx";

export default function Root() {
  return (
    <StrictMode>
      <PropagationLayersProvider>
        <PropagationHistoryProvider>
          <PropagationParametersProvider>
            <App />
          </PropagationParametersProvider>
        </PropagationHistoryProvider>
      </PropagationLayersProvider>
    </StrictMode>
  );
}
