import { IconButton, Slider, Stack, Tooltip, Typography } from "@mui/material";
import GoogleMapsCustomControl from "./GoogleMapsCustomControl";
import { FastRewind, Pause, PlayArrow, Replay } from "@mui/icons-material";
import { usePropagationLayerState } from "../contexts/PropagationLayerStateProvider";
import { useEffect, useRef, useState } from "react";

export default function PlaybackControls(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const { propLayerData, activePropIndex, setActivePropIndex } =
    usePropagationLayerState();
  const intervalRef = useRef(null);
  const activePropIndexRef = useRef(activePropIndex);
  // console.log('rendering PlaybackControls');

  useEffect(() => {
    activePropIndexRef.current = activePropIndex;
  }, [activePropIndex]);

  const startPlayback = () => {
    if (intervalRef.current) return;
    if (activePropIndexRef.current >= propLayerData.images.length - 1) {
      setActivePropIndex(0);
    }
    intervalRef.current = setInterval(() => {
      if (activePropIndexRef.current >= propLayerData.images.length - 1) {
        stopPlayback();
      } else {
        setIsPlaying(true);
        setActivePropIndex((prev) => {
          return prev + 1;
        });
      }
    }, 100);
  };

  const stopPlayback = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (propLayerData) startPlayback();
  }, [propLayerData?.timestamp]);

  return (
    propLayerData && (
      <GoogleMapsCustomControl
        BoxProps={{
          sx: (theme) => ({
            display: "flex",
            alignItems: "center",
            p: 1,
            [theme.breakpoints.down("sm")]: {
              width: "calc(100vw - 20px)",
              marginBottom: "24px",
            },
            // width: { xs: 'calc(100vw - 20px)', sm: 'revert-layer' },
            // marginBottom: {
            // 	xs: '24px',
            // 	sm: 'revert-layer',
            // },
          }),
        }}
      >
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          flexWrap="wrap"
          width="100%"
        >
          <Stack
            direction="row"
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                order: 1,
                ml: "12px !important",
              },
              // order: { xs: 1, sm: 'revert-layer' },
            })}
          >
            <Tooltip title="Rewind">
              <IconButton
                onClick={() => {
                  stopPlayback();
                  setActivePropIndex(0);
                }}
                size="small"
                color="inherit"
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0, 0.05)",
                  },
                }}
              >
                <FastRewind color="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                !isPlaying
                  ? activePropIndexRef.current >=
                    propLayerData.images.length - 1
                    ? "Restart"
                    : "Play"
                  : "Pause"
              }
            >
              <IconButton
                onClick={!isPlaying ? startPlayback : stopPlayback}
                size="small"
                color="inherit"
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0, 0.05)",
                  },
                }}
              >
                {!isPlaying ? (
                  activePropIndexRef.current >=
                  propLayerData.images.length - 1 ? (
                    <Replay color="inherit" />
                  ) : (
                    <PlayArrow color="inherit" />
                  )
                ) : (
                  <Pause color="inherit" />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
          <Slider
            onChange={(e, value) => {
              if (e) {
                stopPlayback();
                setActivePropIndex(value);
              }
            }}
            aria-label="Propagation Time"
            value={activePropIndex}
            max={propLayerData.images.length - 1}
            sx={(theme) => ({
              width: { sm: "40vw", md: "30vw", lg: "20vw" },
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                order: 0,
                marginLeft: "auto",
                marginRight: "24px !important",
              },
            })}
          />
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            pr="5px"
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                order: 1,
                margin: "auto !important",
                transform: "translate(-50%, 0)",
              },
            })}
          >
            <Typography variant="caption" minWidth="3ch" textAlign="right">
              {activePropIndex + 1}
            </Typography>
            <Typography variant="caption">/</Typography>
            <Typography variant="caption">
              {propLayerData.images.length}
            </Typography>
          </Stack>
        </Stack>
      </GoogleMapsCustomControl>
    )
  );
}
