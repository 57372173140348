import { createContext, useContext, useEffect, useState } from "react";

const PropagationLayerStateContext = createContext();

export default function PropagationLayerStateProvider(props) {
  const [propLayerData, setPropLayerData] = useState(null); // contains bounds and image URLs
  const [activePropIndex, setActivePropIndex] = useState(0); // index of active image

  return (
    <PropagationLayerStateContext.Provider
      value={{
        propLayerData,
        setPropLayerData,
        activePropIndex,
        setActivePropIndex,
      }}
    >
      {props.children}
    </PropagationLayerStateContext.Provider>
  );
}

export const usePropagationLayerState = () =>
  useContext(PropagationLayerStateContext);
