import { styled, Tooltip } from "@mui/material";

const StyledButton = styled("button")({
  background: "none rgb(255, 255, 255)",
  border: "0px",
  cursor: "pointer",
  margin: "10px",
  padding: "0px",
  textTransform: "none",
  appearance: "none",
  userSelect: "none",
  borderRadius: "2px",
  height: "40px",
  width: "40px",
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
  overflow: "hidden",
  color: "#666",
  ":hover": {
    color: "rgb(0,0,0)",
  },
});

export default function GoogleMapsCustomButton(props) {
  const { tooltip, tooltipPlacement, ButtonProps } = props;
  // console.log('rendering GoogleMapsCustomButton');
  return (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <StyledButton {...ButtonProps}>{props.children}</StyledButton>
    </Tooltip>
  );
}
