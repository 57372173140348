import { ChevronLeft, History } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import PropagationScenarioItem from "./PropagationScenarioItem";
import { scenarios } from "../../assets/scenarios";

export default function HistoricalFires(props) {
  const { toggleHistoricalFires } = props;
  const theme = useTheme();

  return (
    // <Slide in direction='left'>
    <>
      <Stack direction="row" p={3} pb={0} alignItems="center" spacing={1}>
        <History htmlColor={theme.palette.text.truePrimary} />
        <Typography variant="h5" color={theme.palette.text.truePrimary}>
          Historical Fires
        </Typography>
        <Tooltip title="Previous Runs">
          <IconButton
            onClick={toggleHistoricalFires}
            sx={{
              ml: "auto !important",
            }}
          >
            <ChevronLeft
              htmlColor={theme.palette.text.onTrueSecondary}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                borderRadius: 100,
              }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
      <Box flex={1} overflow="auto" p={3} pt={1}>
        <Stack spacing={2} direction="column-reverse">
          {scenarios.map((scenario) => (
            <PropagationScenarioItem key={scenario.name} scenario={scenario} />
          ))}
        </Stack>
      </Box>
    </>
    // </Slide>
  );
}
