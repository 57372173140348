import {
  Box,
  Divider,
  Grow,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { BookmarkBorder, ContentCopy, Edit } from "@mui/icons-material";
import DirectionPointer from "../DirectionPointer";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { usePropagationLayerState } from "../contexts/PropagationLayerStateProvider";
import { usePropagationParameters } from "../contexts/PropagationParametersProvider";

export default function PropagationHistoryItem(props) {
  const { name, job, selected } = props;
  const [direction, setDirection] = useState(0);
  const theme = useTheme();
  const { setPropLayerData, setActivePropIndex } = usePropagationLayerState();
  const { parameters, setParameters } = usePropagationParameters();
  // console.log('rendering PropagationHistoryItem');

  useEffect(() => {
    setDirection(job.parameters.windDirection);
  }, []);

  const handleClick = () => {
    if (!selected) {
      setPropLayerData({
        bounds: job.bounds,
        images: job.images,
        timestamp: job.timestamp,
      });
      setActivePropIndex(0);
      setParameters(job.parameters);
    }
  };

  return (
    <Grow in style={{ transformOrigin: "0 0 0 " }}>
      <Paper
        onClick={handleClick}
        sx={
          selected
            ? {
                cursor: "pointer",
                m: "-2px !important",
                border: `2px solid ${theme.palette.primary.main}`,
                "&:not(:first-of-type)": {
                  mb: "calc(1em - 2px) !important",
                },
                "&:hover": {
                  border: `2px solid ${theme.palette.primary.dark}`,
                },
              }
            : {
                "&:hover": {
                  cursor: "pointer",
                  m: "-2px",
                  border: "2px solid #fff",
                  "&:not(:first-of-type)": {
                    mb: "calc(1em - 2px) !important",
                  },
                },
              }
        }
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          py={1}
        >
          {/* <Typography variant='h6'>{name}</Typography> */}
          <Typography>{dayjs(job.timestamp).format("h:mmA")}</Typography>
        </Stack>
        <Divider />
        <Stack px={2} py={1} spacing={0.5}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="textSecondary">Location:</Typography>
            <Typography>
              {job.parameters.lat}, {job.parameters.lng}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="textSecondary">Wind Speed:</Typography>
            <Typography>{job.parameters.windSpeed} mph</Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="textSecondary">Wind Direction:</Typography>
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              spacing={1}
            >
              <DirectionPointer
                direction={
                  (Number(direction - (parameters.mapHeading || 0)) + 180) % 360
                }
              />
              <Typography>{job.parameters.windDirection}°</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="textSecondary">
              Fuel Moisture Content:
            </Typography>
            <Typography>{job.parameters.fmc}%</Typography>
          </Stack>
        </Stack>
        {/* <Box position='absolute' bottom={0} right={0} pb={1} pr={1}>
					<Tooltip title='Copy to New Job'>
						<IconButton
							color='primary'
							onClick={(e) => {
								e.stopPropagation();
								setPropagationConfigOpen(true);
							}}
						>
							<ContentCopy />
						</IconButton>
					</Tooltip>
				</Box> */}
      </Paper>
    </Grow>
  );
}
