import { PlayCircleOutline, ScienceOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid2 as Grid,
  Stack,
} from "@mui/material";
import { usePropagationParameters } from "../contexts/PropagationParametersProvider";
import PropagationConfigField from "./PropagationConfigField";
import NdChaos from "../../functions/NdChaos";
import { usePropagationLayerState } from "../contexts/PropagationLayerStateProvider";
import { useEffect, useState } from "react";
import { usePropagationHistory } from "../contexts/PropagationHistoryProvider";

export default function PropagationConfig(props) {
  const [isRunning, setIsRunning] = useState(false);
  const { setPropLayerData, setActivePropIndex } = usePropagationLayerState();
  const { parameters, setParameters } = usePropagationParameters();
  const { addJobToHistory } = usePropagationHistory();
  // console.log('rendering PropagationConfig', parameters);

  const handleChange = (
    e,
    param,
    min = Number.NEGATIVE_INFINITY,
    max = Number.POSITIVE_INFINITY,
    onBlur = false // Converts input to number when the field is unselected. This ensures that the input is valid and doesn't have a period at the end.
  ) => {
    let newParameters = { ...parameters };
    if (e.target.value === "") {
      newParameters[param] = e.target.value;
      setParameters(newParameters);
      return;
    }
    let newValue = Number(e.target.value);

    if (!isNaN(newValue)) {
      if (e.target.value?.split(".")[1]?.length > 6) return; // No more than 6 decimal places to make the UI look nicer

      // This is a hacky check to allow user to type a decimal properly - if we turn the input into a number, it gets rid of a decimal point with no numbers after it
      if (e.target.value.charAt(e.target.value.length - 1) === "." && !onBlur) {
        newParameters[param] = e.target.value.toString();
      } else {
        if (newValue < min) {
          newValue = min;
        } else if (newValue > max) {
          newValue = max;
        }
        if (newValue === 0) newValue = "0";
        newParameters[param] = newValue;
      }
      setParameters(newParameters);
    }
  };

  const handleLiveChange = (param) => {
    let newParameters = { ...parameters };
    newParameters[param] = !newParameters[param];
    setParameters(newParameters);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsRunning(true);

    try {
      const lat = parseFloat(parameters.lat);
      const lon = parseFloat(parameters.lng);

      const response = await fetch(
        "https://t1.alertwest.com/api/propagation/invoke",
        {
          method: "POST",
          // headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            coords: {
              lat,
              lon,
            },
            wind_speed: Number(parameters.windSpeed),
            wind_dir: Number(parameters.windDirection + 270),
            fmc: Number(parameters.fmc) * 0.01,
            humidity: 0.15,
            radius: 10000,
            steps: 501,
          }),
        }
      );
      const buffer = await response.arrayBuffer();
      /*
				Spec:
					Acres Burned: float32 (4 bytes)
					Request ID: char[36] (72 bytes)
					Bounds: float64[4] (32 bytes)
					PNG Data: [:] (variable - the rest of the buffer)
			*/
      let offset = 0;
      let dataView = new DataView(buffer);
      let acresBurned = dataView.getFloat32(offset, true);
      offset += 4;
      let requestId = String.fromCharCode.apply(
        null,
        new Uint8Array(buffer.slice(offset, offset + 36))
      );
      offset += 36;
      let bounds = new Float64Array(buffer.slice(offset, offset + 32));
      offset += 32;

      // Convert binary to base64 in chunks to avoid call stack size exceeded
      const chunk = 8192;
      const bytes = new Uint8Array(buffer.slice(offset));
      const len = bytes.byteLength;
      let binary = "";
      for (let i = 0; i < len; i += chunk) {
        const slice = bytes.slice(i, Math.min(i + chunk, len));
        binary += String.fromCharCode.apply(null, slice);
      }
      let imageUrl = `data:image/png;base64,${btoa(binary)}`;

      let img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);

        let ndManager = new NdChaos(ctx, img.width, img.height, 501).then(
          (ndManager) => {
            const timestamp = Date.now();
            const images = ndManager.imageUrls;
            setPropLayerData({
              bounds,
              images,
              timestamp,
            });
            setActivePropIndex(0);
            addJobToHistory({
              parameters,
              bounds,
              images,
              timestamp,
            });
          }
        );
      };
      img.src = imageUrl;
    } catch (error) {
      console.error(error);
    }
    setIsRunning(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ flexShrink: 1, padding: "16px", overflow: "auto" }}
    >
      <Stack spacing={1.5}>
        <Grid container columns={2} spacing={1}>
          <Grid size={{ xs: 1 }}>
            <PropagationConfigField
              label="Latitude"
              value={parameters.lat || ""}
              onChange={(e) => handleChange(e, "lat", -90, 90)}
              TextFieldProps={{
                disabled: isRunning,
              }}
            />
          </Grid>
          <Grid size={{ xs: 1 }}>
            <PropagationConfigField
              label="Longitude"
              value={parameters.lng || ""}
              onChange={(e) => handleChange(e, "lng", -180, 180)}
              TextFieldProps={{
                disabled: isRunning,
              }}
            />
          </Grid>
        </Grid>
        {/* <Box> */}
        {/* <FormGroup>
						<FormControlLabel
							control={
								<Switch
									value={parameters.liveMode}
									onChange={() => {
										handleLiveChange('liveMode');
									}}
								/>
							}
							label='Use Live Data'
							labelPlacement='start'
						/>
					</FormGroup> */}
        {/* <Stack spacing={1.5}> */}
        <PropagationConfigField
          label="Wind Speed"
          value={parameters.windSpeed || ""}
          onChange={(e) => handleChange(e, "windSpeed", 0)}
          unit="mph"
          min={0}
          // description='Speed of wind in the given direction.'
          // liveToggle={true}
          useLive={parameters.liveMode}
          // onLiveChange={() => handleLiveChange('liveWindSpeed')}
          TextFieldProps={{
            disabled: isRunning,
            onBlur: (e) => handleChange(e, "windSpeed", 0, 253, true),
          }}
        />
        <PropagationConfigField
          label="Wind Direction"
          value={parameters.windDirection || ""}
          mapHeading={parameters.mapHeading || 0}
          onChange={(e) => handleChange(e, "windDirection", 0, 360)}
          unit="°"
          // description='Direction wind is coming from in clockwise degrees where 0 is north.'
          // liveToggle={true}
          useLive={parameters.liveMode}
          // onLiveChange={() => handleLiveChange('liveWindDirection')}
          TextFieldProps={{
            disabled: isRunning,
            onBlur: (e) => handleChange(e, "windDirection", 0, 360, true),
          }}
        />
        <PropagationConfigField
          label="Fuel Moisture Content"
          value={parameters.fmc || ""}
          onChange={(e) => handleChange(e, "fmc", 0)}
          unit="%"
          // description='Amount of water in fuel available to a fire.'
          // liveToggle={true}
          useLive={parameters.liveMode}
          // onLiveChange={() => handleLiveChange('liveFmc')}
          TextFieldProps={{
            disabled: isRunning,
            onBlur: (e) => handleChange(e, "fmc", 0, 100, true),
          }}
        />
        {/* </Stack> */}
        {/* </Box> */}
      </Stack>
      <Box mt={2} display="flex" justifyContent="center">
        <Box position="relative">
          <Button
            size="large"
            variant="contained"
            startIcon={<PlayCircleOutline />}
            fullWidth
            type="submit"
            disabled={
              !(
                parameters.lat &&
                parameters.lng &&
                ((!parameters.liveMode &&
                  (parameters.windSpeed || parameters.liveWindSpeed) &&
                  (parameters.windDirection || parameters.liveWindDirection) &&
                  (parameters.fmc || parameters.liveFmc)) ||
                  parameters.liveMode)
              ) || isRunning
            }
            sx={{ fontWeight: 600 }}
          >
            Run Propagation
          </Button>
          {isRunning && (
            <CircularProgress
              size={28}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-14px",
                marginLeft: "-14px",
              }}
            />
          )}
        </Box>
      </Box>
    </form>
  );
}
