import * as React from "react";
import { useMap } from "@vis.gl/react-google-maps";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { GoogleMapsOverlay } from "@deck.gl/google-maps";

const DeckGLOverlay = React.forwardRef((props, ref) => {
  const { mapId } = props;
  const map = useMap(mapId);
  const deck = React.useMemo(
    () => new GoogleMapsOverlay({ interleaved: true }),
    []
  );
  React.useEffect(() => {
    deck.setMap(map);
    ref.current = deck;
  }, [map]);
});

export default React.memo(
  React.forwardRef(function ReactGoogleMap(props, ref) {
    const { MapProps, mapId } = props;
    // console.log('rendering BaseMap');

    return (
      <>
        <Map id={mapId} {...MapProps}>
          {props.children}
        </Map>
        <DeckGLOverlay mapId={mapId} ref={ref} />
      </>
    );
  })
);
