import {
  Box,
  Divider,
  IconButton,
  Paper,
  Slide,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import PropagationHistoryStack from "./PropagationHistoryStack";
import PropagationConfig from "./PropagationConfig";
import { AddCircleOutline, ChevronRight, History } from "@mui/icons-material";
import PreviousRuns from "./PreviousRuns";
import HistoricalFires from "./HistoricalFires";
import { useRef, useState } from "react";

export default function PropagationSidebar(props) {
  const [showHistoricalFires, setShowHistoricalFires] = useState(false);
  const bottomContainerRef = useRef(null);
  const theme = useTheme();
  // console.log('rendering PropagationSidebar');

  const toggleHistoricalFires = () => {
    setShowHistoricalFires((prev) => !prev);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="360px"
      // width='fit-content'
    >
      <Box flexShrink={0}>
        <Stack direction="row" p={3} pb={0} alignItems="center" spacing={1}>
          <AddCircleOutline htmlColor={theme.palette.text.truePrimary} />
          <Typography variant="h5" color={theme.palette.text.truePrimary}>
            New Run
          </Typography>
        </Stack>
        <Paper sx={{ width: "fit-content", m: 3, mt: 1 }}>
          <PropagationConfig />
        </Paper>
      </Box>
      <Divider />
      <Box overflow="hidden" position="relative" flex={1}>
        <Box
          position="absolute"
          width="100%"
          height="100%"
          zIndex={!showHistoricalFires ? 1 : 0}
        >
          <Slide
            in={!showHistoricalFires}
            direction="right"
            mountOnEnter
            unmountOnExit
          >
            <Box height="100%" display="flex" flexDirection="column">
              <PreviousRuns toggleHistoricalFires={toggleHistoricalFires} />
            </Box>
          </Slide>
        </Box>
        <Box
          position="absolute"
          width="100%"
          height="100%"
          zIndex={showHistoricalFires ? 1 : 0}
        >
          <Slide
            in={showHistoricalFires}
            direction="left"
            mountOnEnter
            unmountOnExit
          >
            <Box height="100%" display="flex" flexDirection="column">
              <HistoricalFires toggleHistoricalFires={toggleHistoricalFires} />
            </Box>
          </Slide>
        </Box>
      </Box>

      {/* <Box ref={bottomContainerRef} overflow='hidden' display='flex'>
				<Slide
					in={!showHistoricalFires}
					container={bottomContainerRef.current}
					mountOnEnter
					unmountOnExit
					direction='left'
				>
					<Box width='100%' flex>
						<PreviousRuns toggleHistoricalFires={toggleHistoricalFires} />
					</Box>
				</Slide>
				<Slide
					in={showHistoricalFires}
					container={bottomContainerRef.current}
					mountOnEnter
					unmountOnExit
					direction='right'
				>
					<Box width='100%'>
						<HistoricalFires toggleHistoricalFires={toggleHistoricalFires} />
					</Box>
				</Slide>
			</Box> */}

      {/* <Stack direction='row' p={3} pb={0} alignItems='center' spacing={1}>
				<History htmlColor={theme.palette.text.truePrimary} />
				<Typography variant='h5' color={theme.palette.text.truePrimary}>
					Previous Runs
				</Typography>
				<IconButton
					sx={{
						ml: 'auto !important',
					}}
				>
					<ChevronRight
						htmlColor={theme.palette.text.onTrueSecondary}
						sx={{
							backgroundColor: theme.palette.secondary.main,
							borderRadius: 100,
						}}
					/>
				</IconButton>
			</Stack>
			<Box flex={1} overflow='auto' p={3} pt={1}>
				<PropagationHistoryStack
				// setPropagationConfigOpen={setPropagationConfigOpen}
				/>
			</Box> */}
    </Box>
  );
}
