import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import "@fontsource/montserrat";
import PropagationMap from "./components/map/PropagationMap";
import PropagationSidebar from "./components/sidebar/PropagationSidebar";
import { APIProvider } from "@vis.gl/react-google-maps";
import { theme } from "./theme";

// const refTheme = createTheme();
// const theme = createTheme({
//   palette: {
//     mode: "dark",
//     background: {
//       paper: "#251f15",
//       default: "#18120a",
//     },
//     primary: {
//       main: "#fdb01e",
//       contrastText: "#6a4600",
//     },
//     secondary: {
//       main: "#614311",
//       contrastText: "#dbb275",
//     },
//     tertiary: {
//       main: "#b5c942",
//       contrastTexT: "#485300",
//     },
//     error: {
//       main: "#ffb4ab",
//       contrastText: "#690005",
//     },
//     text: {
//       primary: "#eee0d1",
//       secondary: "#d6c4ad",
//       truePrimary: "#ffd497",
//       trueSecondary: "#eac082",
//       onTruePrimary: "#6a4600",
//       onTrueSecondary: "#dbb275",
//       onTrueTertiary: "#485300",
//     },
//   },
//   typography: {
//     fontFamily: "Montserrat",
//   },
//   components: {
//     MuiPaper: {
//       styleOverrides: {
//         root: { borderRadius: "12px" },
//       },
//       variants: [
//         {
//           props: { variant: "primary" },
//           style: {
//             backgroundColor: "#ffdeb3",
//             color: "#3c2600",
//           },
//         },
//         {
//           props: { variant: "secondary" },
//           style: {
//             backgroundColor: "#efc485",
//             color: "#221300",
//           },
//         },
//         {
//           props: { variant: "tertiary" },
//           style: {
//             backgroundColor: "#d9ee63",
//             color: "#272d00",
//           },
//         },
//       ],
//     },
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: "21px",
//         },
//       },
//     },
//     MuiToggleButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: "14px",
//         },
//       },
//     },
//     MuiDivider: {
//       styleOverrides: {
//         root: {
//           backgroundColor: "#91816c",
//         },
//       },
//     },
//     MuiCssBaseline: {
//       styleOverrides: {
//         body: {
//           "*::-webkit-scrollbar": {
//             width: "15px",
//           },
//           "*::-webkit-scrollbar-thumb": {
//             borderRadius: "10px",
//             border: "3px solid rgba(0, 0, 0, 0.00)",
//             background: "rgba(255, 255, 255, 0.3)",
//             WebkitBackgroundClip: "padding-box",
//             MozBackgroundClip: "padding",
//             backgroundClip: "padding-box",
//           },
//           "*::-webkit-scrollbar-thumb:hover": {
//             border: "2px solid rgba(0, 0, 0, 0.00)",
//             backgroundColor: "rgba(255, 255, 255, 0.4)",
//           },
//         },
//       },
//     },
//   },
// });

function App() {
  const mapApiKey = process.env.GOOGLE_MAPS_API_KEY;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        maxWidth={false}
        disableGutters
        sx={{ display: "flex", height: "calc(100vh - 64px)" }}
      >
        <APIProvider apiKey={mapApiKey}>
          <Box height="100%" flex={1} overflow="hidden">
            <PropagationMap />
          </Box>
          <Box height="100%" flexShrink={1}>
            <PropagationSidebar />
          </Box>
        </APIProvider>

        {/* <Grid container columns={32} height='100%' overflow='hidden'>
					<Grid size={{ xs: 26 }}>
						<Box height='100%'>
							<PropagationMap
								setPropagationConfigOpen={setPropagationConfigOpen}
							/>
						</Box>
					</Grid>
					<Grid
						size={{ xs: 6 }}
						height='100vh'
						sx={{ backgroundColor: theme.palette.background.default }}
					>
						<PropagationSidebar
							setPropagationConfigOpen={setPropagationConfigOpen}
						/>
					</Grid>
				</Grid> */}
      </Container>

      {/* <Modal
				open={propagationConfigOpen}
				onClose={() => setPropagationConfigOpen(false)}
			>
				<Paper
					sx={{
						display: 'flex',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						maxHeight: {
							xs: '80%',
						},
						width: {
							xs: '90%',
							sm: '80%',
							md: '50%',
							lg: '40%',
							xl: '30%',
						},
						// p: 2,
					}}
				>
					<PropagationConfigForm />
				</Paper>
			</Modal> */}
    </ThemeProvider>
  );
}

export default App;
