import { createContext, useContext, useState } from "react";

const PropagationParametersContext = createContext();
export const usePropagationParameters = () =>
  useContext(PropagationParametersContext);

export default function PropagationParametersProvider(props) {
  const [parameters, setParameters] = useState({
    lat: null,
    lng: null,
    windSpeed: null,
    liveWindSpeed: false,
    windDirection: null,
    liveWindDirection: false,
    fmc: null,
    liveFmc: false,
    liveMode: false,
  });

  return (
    <PropagationParametersContext.Provider
      value={{ parameters, setParameters }}
    >
      {props.children}
    </PropagationParametersContext.Provider>
  );
}
