import { BitmapLayer, PolygonLayer } from "@deck.gl/layers";
import { usePropagationLayerState } from "../contexts/PropagationLayerStateProvider";
import { useMemo } from "react";

export const usePropagationLayer = () => {
  const { propLayerData, activePropIndex } = usePropagationLayerState();

  const propLayer = useMemo(() => {
    if (!propLayerData?.images[activePropIndex]) return null;

    return new BitmapLayer({
      id: "propagation-layer",
      bounds: propLayerData.bounds,
      image: propLayerData.images[activePropIndex],
      opacity: 0.8,
      textureParameters: {
        minFilter: "nearest",
        magFilter: "nearest",
      },
    });
  }, [propLayerData?.bounds, propLayerData?.images, activePropIndex]);

  const boundsLayer = useMemo(() => {
    if (!propLayerData?.bounds) return null;
    const bounds = propLayerData.bounds;

    return new PolygonLayer({
      id: "bounds-layer",
      data: [
        [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[1]],
          [bounds[2], bounds[3]],
          [bounds[0], bounds[3]],
          [bounds[0], bounds[1]],
        ],
      ],
      getPolygon: (d) => d,
      getLineColor: [0, 0, 0],
      // getFillColor: [0, 0, 0, 0],
      lineWidthMinPixels: 2,
      stroked: true,
      filled: false,
    });
  }, [propLayerData?.bounds]);

  const propBounds = propLayerData?.bounds;

  return { propLayer, boundsLayer, propBounds };
};
