import { createContext, useContext, useState } from "react";

const PropagationHistoryContext = createContext();

export default function PropagationHistoryProvider(props) {
  const [history, setHistory] = useState([]);

  const addJobToHistory = (job) => setHistory((prev) => [...prev, job]);

  return (
    <PropagationHistoryContext.Provider
      value={{ history, addJobToHistory: addJobToHistory }}
    >
      {props.children}
    </PropagationHistoryContext.Provider>
  );
}

export const usePropagationHistory = () =>
  useContext(PropagationHistoryContext);
