import {
  Box,
  Divider,
  Grow,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import DirectionPointer from "../DirectionPointer";
import { usePropagationParameters } from "../contexts/PropagationParametersProvider";
import { useState } from "react";
import { useMap } from "@vis.gl/react-google-maps";

export default function PropagationScenarioItem(props) {
  const { scenario } = props;
  const [expanded, setExpanded] = useState(false);
  const { setParameters } = usePropagationParameters();
  const map = useMap("propagation-map");

  const handleClick = () => {
    // Convert tuple to separate lat/lng values
    const [lat, lng] = scenario.start_loc;

    // Convert wind direction from meteorological to mathematical
    // and adjust for the 270° offset in the scenarios
    const windDir = (scenario.wind_dir - 270) % 360;

    setParameters({
      lat: lat,
      lng: lng,
      windSpeed: scenario.wind_speed,
      windDirection: windDir,
      fmc: Number(Math.floor(scenario.fmc * 100)), // Convert from decimal to percentage
      liveMode: false,
    });

    map.setCenter({ lat: lat, lng: lng });
  };

  const handleExpandClick = (e) => {
    e.stopPropagation(); // Prevent the parent onClick from firing
    setExpanded(!expanded);
  };

  return (
    <Grow in style={{ transformOrigin: "0 0 0" }}>
      <Paper
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          "&:hover": {
            m: "-2px",
            border: "2px solid #fff",
            "&:not(:first-of-type)": {
              mb: "calc(1em - 2px) !important",
            },
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          py={1}
        >
          <Typography fontWeight={500}>{scenario.name}</Typography>
          <IconButton onClick={handleExpandClick} size="small" color="primary">
            {expanded ? <Remove /> : <Add />}
          </IconButton>
        </Stack>
        {expanded && (
          <>
            <Divider />
            <Stack px={2} py={1} spacing={0.5}>
              <Stack direction="row" justifyContent="space-between">
                <Typography color="textSecondary">Location:</Typography>
                <Typography>
                  {scenario.start_loc[0]}, {scenario.start_loc[1]}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography color="textSecondary">Wind Speed:</Typography>
                <Typography>{scenario.wind_speed} mph</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography color="textSecondary">Wind Direction:</Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <DirectionPointer
                    direction={(Number(scenario.wind_dir - 270) + 180) % 360}
                  />
                  <Typography>{scenario.wind_dir - 270}°</Typography>
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography color="textSecondary">
                  Fuel Moisture Content:
                </Typography>
                <Typography>{Math.floor(scenario.fmc * 100)}%</Typography>
              </Stack>
            </Stack>
          </>
        )}
      </Paper>
    </Grow>
  );
}
