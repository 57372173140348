import { memo, useEffect, useRef, useState } from "react";
import BaseMap from "./BaseMap";
import {
  AdvancedMarker,
  ControlPosition,
  MapControl,
  Pin,
  useMap,
} from "@vis.gl/react-google-maps";
import PlaybackControls from "./PlaybackControls";
import { createTheme, ScopedCssBaseline, ThemeProvider } from "@mui/material";
import LayersPopover from "./LayersPopover";
import { usePropagationParameters } from "../contexts/PropagationParametersProvider";
import { usePropagationLayer } from "../hooks/usePropagationLayer";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#304ffe",
    },
    secondary: {
      main: "#fdb01e",
    },
    contrastThreshold: 4.5,
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "*::-webkit-scrollbar-thumb": {
            background: "rgba(0, 0, 0, 0.3)",
          },
          "*::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        },
      },
    },
  },
});

export default memo(function PropagationMap(props) {
  const [layers, setLayers] = useState(null);
  const map = useMap("propagation-map");
  const deckRef = useRef(null);
  const { parameters, setParameters } = usePropagationParameters();
  const { propLayer, boundsLayer, propBounds } = usePropagationLayer();
  // console.log('rendering PropagationMap');

  useEffect(() => {
    deckRef.current.setProps({ layers });
  }, [layers]);

  useEffect(() => {
    setLayers([propLayer, boundsLayer]);
  }, [propLayer, boundsLayer]);

  useEffect(() => {
    if (propBounds) {
      map.fitBounds({
        north: propBounds[3],
        south: propBounds[1],
        west: propBounds[0],
        east: propBounds[2],
      });
    }
  }, [propBounds]);

  const handleMapClick = (e) => {
    setParameters((prev) => ({
      ...prev,
      lat: Number(e.detail.latLng.lat.toFixed(6)),
      lng: Number(e.detail.latLng.lng.toFixed(6)),
    }));
  };

  const handleHeadingChanged = (e) => {
    setParameters((prev) => ({
      ...prev,
      mapHeading: e.detail.heading,
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline sx={{ height: "100%" }}>
        <BaseMap
          MapProps={{
            defaultBounds: {
              north: 42,
              south: 32,
              west: -124,
              east: -114,
            },
            streetViewControl: false,
            mapId: "bef4b4e793890677",
            mapTypeId: "terrain",
            onClick: handleMapClick,
            onHeadingChanged: handleHeadingChanged,
            // options: {
            // 	clickableIcons: false,
            // 	styles: [
            // 		{
            // 			featureType: 'poi',
            // 			elementType: 'all',
            // 			stylers: [{ visibility: 'off' }],
            // 		},
            // 	],
            // },
          }}
          mapId="propagation-map"
          ref={deckRef}
        >
          <MapControl position={ControlPosition.BOTTOM_CENTER}>
            <PlaybackControls />
          </MapControl>
          {/* <MapControl position={ControlPosition.RIGHT_TOP}>
						<LayersPopover />
					</MapControl> */}
          {parameters.lat && parameters.lng && (
            <AdvancedMarker
              position={{
                lat: Number(parameters.lat),
                lng: Number(parameters.lng),
              }}
            >
              <Pin background="#fdb01e" borderColor="#000" glyphColor="#000" />
            </AdvancedMarker>
          )}
        </BaseMap>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
});
