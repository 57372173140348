import { Stack, Typography } from "@mui/material";
import PropagationHistoryItem from "./PropagationHistoryItem";
import { usePropagationHistory } from "../contexts/PropagationHistoryProvider";
import { usePropagationLayerState } from "../contexts/PropagationLayerStateProvider";
import { memo } from "react";

export default memo(function PropagationHistoryStack(props) {
  const { setPropagationConfigOpen } = props;
  const { history } = usePropagationHistory();
  const { propLayerData } = usePropagationLayerState();
  // console.log('rendering PropagationHistoryStack');

  return history.length > 0 ? (
    <Stack spacing={2} direction="column-reverse">
      {history.map((job) => {
        return (
          <PropagationHistoryItem
            key={job.timestamp}
            job={job}
            selected={propLayerData.timestamp === job.timestamp}
          />
        );
      })}
    </Stack>
  ) : (
    <Typography>
      After you run propagation jobs, you can return to them here.
    </Typography>
  );
});
